// Vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import Autosuggest from "react-autosuggest";
import { withTranslation, Trans } from "react-i18next";
import {
  connectAutoComplete,
  InstantSearch,
  Configure,
  Index
} from "react-instantsearch-dom";
import SVG from "react-inlinesvg";
import { Link, withRouter } from "react-router-dom";
import { Button } from "reactstrap";

// App
import Algolia from "common/Algolia";
import EventEmitterClient from "common/EventEmitterClient";
import MatchHelper from "common/MatchHelper";
import { SEARCH_ROUTE, GOLF_CLUB_ROUTE, isHome } from "common/RoutesHelper";
import golfClubIcon from "images/amenities/practice.svg";
import URLHelper from "common/URLHelper";

const removeDuplicateAreas = (arr, prop, needle) => {
  let unique = {
    values: [],
    items: []
  };

  arr.forEach(item => {
    if (item.hasOwnProperty(prop) && Array.isArray(item[prop])) {
      item[prop].forEach(value => {
        // if the query matches the current hit's area and is not included in the return array
        // we add it to the returned list of areas (rendered)
        if (
          MatchHelper.isMatch(needle, value) &&
          !unique.values.includes(value)
        ) {
          unique.values.push(value);
          // Because it's the same object for multiple hits, we need to clone it so we can set the area property
          // on it. otherwise we override it on the next iteration
          unique.items.push({ ...item, area: value });
        }
      });
    }
  });
  return unique.items;
};

const GEO_ATTRS = ["area", "city", "country", "query", "state"];

class _AutoComplete extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired
    })
  };

  constructor(props) {
    super(props);

    this.state = {
      value: "",
      hits: []
    };

    this.hasKeyboardSelection = false;
    this.searchContainerRef = React.createRef();

    this.blurSearch = this.blurSearch.bind(this);
    this.emptySearch = this.emptySearch.bind(this);
    this.getSectionSuggestions = this.getSectionSuggestions.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleResetBtnBlur = this.handleResetBtnBlur.bind(this);
    this.handleSearchAllClick = this.handleSearchAllClick.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleSearchBlur = this.handleSearchBlur.bind(this);
    this.handleSearchFocus = this.handleSearchFocus.bind(this);
    this.handleSuggestionHighlighted = this.handleSuggestionHighlighted.bind(
      this
    );
    this.handleSuggestionsClearRequested = this.handleSuggestionsClearRequested.bind(
      this
    );
    this.handleSuggestionsFetchRequested = this.handleSuggestionsFetchRequested.bind(
      this
    );
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);
    this.navigate = this.navigate.bind(this);
    this.renderSectionTitle = this.renderSectionTitle.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.renderSuggestionsContainer = this.renderSuggestionsContainer.bind(
      this
    );
  }

  componentDidMount() {
    this.setState({ hits: this.props.hits });
    EventEmitterClient.on("QUERY_RESET", this.emptySearch);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.emptySearch();
      // FIX: to prevent search to staying focused when changing page
      if (this.input) {
        this.input.disabled = true;
        this.input.disabled = false;
      }
    }
  }

  componentWillUnmount() {
    if (this.input) {
      this.input.removeEventListener("keypress", this.handleKeyPress);
      this.input.removeEventListener("focus", this.handleSearchFocus);
      this.input.removeEventListener("blur", this.handleSearchBlur);
    }
    EventEmitterClient.removeEventListener("QUERY_RESET", this.emptySearch);
  }

  blurSearch() {
    EventEmitterClient.emit("SEARCH_BLUR");
    if (this.input) {
      this.input.disabled = true;
      this.input.disabled = false;
    }
  }

  emptySearch() {
    this.setState({ value: "", searchValue: "" });
  }

  getSectionSuggestions(section) {
    const { hits } = section;

    // clone hits and add index
    let newHits = hits.map(hit => ({ ...hit, index: section.index }));

    // remove duplicate entries for areas, which uses a string array
    // the other indices use a string property, and has a distinct setting,
    // so only unique items are returned.
    //
    // Use the actual search value instead of the display value
    if (section.index === Algolia.areasIndex.value) {
      newHits = removeDuplicateAreas(newHits, "areas", this.state.searchValue);
    }

    // the results is narrowed down to numHits through our <Configure /> tag.
    // But because each hit can have multiple areas, and to prevent autocomplete
    // menu to have too many items, we also narrow down areas to numHits.
    return newHits.slice(0, this.props.numHits);
  }

  getSuggestionValue(hit) {
    switch (hit.index) {
      case Algolia.citiesIndex.value:
        return hit.city;
      case Algolia.areasIndex.value:
        return hit.area;
      case Algolia.countriesIndex.value:
        return hit.country;
      default:
        return hit.name;
    }
  }

  handleChange = (event, { newValue, method }) => {
    let newState = {};

    if (this.state.value !== newValue) newState.value = newValue;

    switch (method) {
      case "up":
      case "down":
        // There is a race condition between handleChange and handleSuggestionHighlighted.
        // To ensure that hasKeyboardSelection gets correct value we need to use a timeout
        // to add this method to the call stack which is resolved after handleSuggestionHighlighted
        // is run. However, we do nothing if the search string is the same as the new value (no selection)
        if (this.state.searchValue !== newValue) {
          setTimeout(() => {
            this.hasKeyboardSelection = true;
          });
        }
        break;

      // we separate the display value and the entered search value
      // so that when filtering the areas, we compare it to the search value
      // and not the value being set when arrowing up and down the menu items
      case "type":
        newState.searchValue = newValue;
        break;

      case "enter":
        newState.value = this.hasKeyboardSelection
          ? newValue
          : this.state.value;
        break;

      case "escape":
        this.blurSearch();
        break;

      default:
        break;
    }

    if (Object.keys(newState).length) this.setState(newState);
  };

  handleClubClick(event, slug) {
    event.preventDefault();
    const newLocation = GOLF_CLUB_ROUTE.url(slug);
    const replace = this.props.location.pathname === newLocation;
    this.navigate(newLocation, replace);
  }

  handleKeyPress(e) {
    const key = e.which || e.keyCode;

    // make query if user presses enter button and no selection is made
    if (key === 13 && !this.hasKeyboardSelection) {
      if (this.state.value)
        this.handleSearchClick(e, "query", this.state.value);
      else this.handleSearchAllClick(e);
    }
  }

  handleResetBtnBlur(e) {
    if (e.relatedTarget && e.relatedTarget !== this.input)
      EventEmitterClient.emit("SEARCH_BLUR");
  }

  handleSearchAllClick(event) {
    event.preventDefault();

    const { location } = this.props;
    const isSearchPage = isHome(location.pathname);
    const noGeoParams = !URLHelper.hasAnyOfParams(location, GEO_ATTRS);
    const replace = isSearchPage && noGeoParams;

    this.navigate(
      {
        pathname: SEARCH_ROUTE.url(),
        state: { external: true }
      },
      replace
    );
  }

  handleSearchClick(event, param, value) {
    event.preventDefault();

    this.navigate(
      {
        pathname: SEARCH_ROUTE.url(),
        search: `?${param}=${encodeURIComponent(value)}`,
        state: { external: true }
      },
      URLHelper.hasParam(this.props.location, param, value)
    );
  }

  handleSearchBlur(event) {
    const newTarget = event.relatedTarget;

    if (!newTarget) {
      EventEmitterClient.emit("SEARCH_BLUR");
    }
    // If the new focus element is outside the search container
    if (newTarget && !this.searchContainerRef.current.contains(newTarget)) {
      EventEmitterClient.emit("SEARCH_BLUR");
    }
  }

  handleSearchFocus() {
    EventEmitterClient.emit("SEARCH_FOCUS");

    const search = this.searchContainerRef;

    // Scroll element into view on focus
    if (this.input) {
      const { y } = this.input.getBoundingClientRect();
      const margin = -20;
      window.scrollTo(0, y + window.pageYOffset + margin);
    }

    document.addEventListener(
      "mousedown",
      function clickOutsideListener(event) {
        if (
          search &&
          search.current &&
          !search.current.contains(event.target)
        ) {
          EventEmitterClient.emit("SEARCH_BLUR");
          document.removeEventListener("mousedown", clickOutsideListener, true);
        }
      },
      true
    );
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    this.props.refine(value);
  };

  handleSuggestionsClearRequested = () => {
    this.setState({ hits: [] });
  };

  handleSuggestionHighlighted = () => {
    // we reset any previous selection because we cannot distinguish
    // if the selection is made by keyboard or mouse hover
    // this is set again in onChange on "up" and "down" events
    this.hasKeyboardSelection = false;
  };

  handleSuggestionSelected = (event, { suggestion, method }) => {
    if (this.hasKeyboardSelection || method === "click") {
      switch (suggestion.index) {
        case Algolia.citiesIndex.value:
          this.handleSearchClick(event, "city", suggestion.city);
          break;
        case Algolia.areasIndex.value:
          this.handleSearchClick(event, "area", suggestion.area);
          break;
        case Algolia.countriesIndex.value:
          this.handleSearchClick(event, "country", suggestion.country);
          break;
        default:
          this.handleClubClick(event, suggestion.slug);
          break;
      }
    } else {
      this.handleSearchClick(event, "area", this.state.value);
    }
  };

  navigate(location, replace) {
    const { history } = this.props;

    this.blurSearch();

    if (replace) history.replace(location);
    else history.push(location);
  }

  renderSectionTitle(section) {
    const { t } = this.props;
    if (!section.hits.length) return null;

    switch (section.index) {
      case Algolia.citiesIndex.value:
        return t("cities");
      case Algolia.areasIndex.value:
        return t("areas");
      case Algolia.countriesIndex.value:
        return t("countries.countries");
      default:
        return t("golf_courses");
    }
  }

  renderSuggestion(hit, search) {
    const { t } = this.props;
    switch (hit.index) {
      case Algolia.citiesIndex.value:
        return (
          <Fragment>
            <div className="react-autosuggest__suggestion__icon-container">
              <FontAwesomeIcon icon="map-marker-alt" />
            </div>
            <div>
              <Link
                to="/search"
                onClick={e => {
                  this.handleSearchClick(e, "city", hit.city);
                }}
              >
                {hit.city}
              </Link>
              <p>
                {hit.state}, {hit.country}
              </p>
            </div>
          </Fragment>
        );

      case Algolia.areasIndex.value:
        return (
          <Fragment>
            <div className="react-autosuggest__suggestion__icon-container">
              <FontAwesomeIcon icon="map-marker-alt" />
            </div>
            <div>
              <Link
                to="/search"
                onClick={e => {
                  this.handleSearchClick(e, "area", hit.area);
                }}
              >
                {hit.area}
              </Link>
              <p>{hit.country}</p>
            </div>
          </Fragment>
        );

      case Algolia.countriesIndex.value:
        return (
          <Fragment>
            <div className="react-autosuggest__suggestion__icon-container">
              <FontAwesomeIcon icon="globe" />
            </div>
            <div>
              <Link
                to="/search"
                onClick={e => {
                  this.handleSearchClick(e, "country", hit.country);
                }}
              >
                {hit.country}
              </Link>
            </div>
          </Fragment>
        );

      default:
        return (
          <Fragment>
            <div className="react-autosuggest__suggestion__icon-container">
              <SVG title={t("golf_club")} className="isvg" src={golfClubIcon} />
            </div>
            <div>
              <Link
                to={GOLF_CLUB_ROUTE.url(hit.slug)}
                onClick={e => {
                  this.handleClubClick(e, hit.slug);
                }}
              >
                {hit.name}
              </Link>
              <p>
                {hit.city}, {hit.state}, {hit.country}
              </p>
            </div>
          </Fragment>
        );
    }
  }

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    const { t, hits } = this.props;
    const hasHits = hits.find(index => index.hits.length > 0) !== undefined;
    let nearMe = null;
    let allClubs = null;
    let noHits = null;

    if (this.input) {
      let hasFocus = document.activeElement === this.input;

      if (hasFocus && !query.length) {
        nearMe = (
          <li className="react-autosuggest__suggestion my-2">
            <div className="react-autosuggest__suggestion__icon-container mt-0">
              <FontAwesomeIcon icon="map-marker-alt" />
            </div>
            <Link
              to="/search"
              className="flex-grow-1"
              onClick={e => {
                this.handleSearchClick(e, "sortBy", Algolia.rangeIndex.type);
              }}
            >
              <span className="d-inline-block ml-1">{t("near_me")}</span>
            </Link>
          </li>
        );

        allClubs = (
          <li className="react-autosuggest__suggestion my-2">
            <div className="react-autosuggest__suggestion__icon-container mt-0">
              <SVG title={t("golf_club")} className="isvg" src={golfClubIcon} />
            </div>
            <Link
              to="/search"
              className="flex-grow-1"
              onClick={this.handleSearchAllClick}
            >
              <span className="d-inline-block ml-1">
                {t("show_all_golf_courses")}
              </span>
            </Link>
          </li>
        );
      }
      if (hasFocus && !hasHits && query.length) {
        noHits = (
          <li className="react-autosuggest__suggestion no-hits my-2 text-black-50">
            <span>
              <Trans i18nKey="no_results">
                No search results for <strong>{{ query }}</strong>
              </Trans>
            </span>
          </li>
        );
      }
    }

    return (
      <div {...containerProps}>
        {nearMe}
        {noHits}
        {allClubs}
        {children}
      </div>
    );
  };

  storeInputReference = autosuggest => {
    if (autosuggest) {
      this.input = autosuggest.input;
      this.input.addEventListener("keypress", this.handleKeyPress);
      this.input.addEventListener("focus", this.handleSearchFocus);
      this.input.addEventListener("blur", this.handleSearchBlur, true);
    }
  };

  render() {
    const { hits, t, autoFocus } = this.props;
    const inputProps = {
      placeholder: t("placeholder"),
      value: this.state.value,
      autoFocus: autoFocus,
      onChange: this.handleChange
    };

    return (
      <div className="Autosuggest" ref={this.searchContainerRef}>
        <Autosuggest
          focusInputOnSuggestionClick={false}
          getSectionSuggestions={this.getSectionSuggestions}
          getSuggestionValue={this.getSuggestionValue}
          inputProps={inputProps}
          multiSection={true}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionHighlighted={this.handleSuggestionHighlighted}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          onSuggestionSelected={this.handleSuggestionSelected}
          ref={this.storeInputReference}
          renderSectionTitle={this.renderSectionTitle}
          renderSuggestion={this.renderSuggestion}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          suggestions={hits}
        />
        {this.state.value && (
          <Button
            color="link"
            className="text-black-50 react-autosuggest__clear-btn"
            onClick={() => {
              this.input.focus();
              this.emptySearch();
            }}
            onBlur={this.handleResetBtnBlur}
          >
            <FontAwesomeIcon
              className="text-muted"
              icon="times-circle"
              size="sm"
            />
          </Button>
        )}
      </div>
    );
  }
}

const AutoComplete = connectAutoComplete(
  withRouter(withTranslation()(_AutoComplete))
);

class AutoCompleteSearch extends Component {
  static propTypes = {
    numHits: PropTypes.number,
    autoFocus: PropTypes.bool.isRequired
  };

  static defaultProps = {
    className: "",
    numHits: 5,
    autoFocus: false
  };

  render() {
    return (
      <div className={`AutoCompleteSearch ${this.props.className}`}>
        <InstantSearch
          appId={Algolia.appId}
          apiKey={Algolia.apiKey}
          indexName={Algolia.areasIndex.value}
        >
          <Configure hitsPerPage={this.props.numHits} />
          <AutoComplete {...this.props} />
          <Index indexName={Algolia.citiesIndex.value} />
          <Index indexName={Algolia.clubsIndex.value} />
          <Index indexName={Algolia.countriesIndex.value} />
        </InstantSearch>
      </div>
    );
  }
}

export default AutoCompleteSearch;
