// Vendor
import PropTypes from "prop-types";
import React from "react";
import { graphql } from "react-apollo";

// App
import Auth from "common/Auth";
import LocaleHelper from "common/LocaleHelper";
import { UPDATE_USER_PROFILE_MUTATION } from "common/Mutations";
import FlagIcon from "components/ui/FlagIcon";
import { Button } from "reactstrap";

function LanguageList(props) {
  async function handleLanguageChange(locale) {
    const { language } = locale;

    // callback
    props.onChange(locale);

    if (props.updateLangOnChange) {
      // Globally update language in app
      LocaleHelper.load(language);

      // Update user in local storage if signed in
      const user = Auth.getUser();
      if (user) {
        await props.updateUserProfileMutation({
          variables: {
            input: {
              firstName: user.firstName,
              lastName: user.lastName,
              newsletter: user.newsletter,
              language
            }
          }
        });
        Auth.setUser({ ...user, language });
      }
    }
  }

  const { language } = LocaleHelper.getLocale();

  return LocaleHelper.getLocales().map((locale, index) => (
    <Button
      color="link"
      className={`${props.className} ${
        language === locale.language ? "active" : ""
      }`}
      key={index}
      onClick={() => handleLanguageChange(locale)}
    >
      <FlagIcon code={locale.country} className="mr-2" />
      {locale.label}
    </Button>
  ));
}

LanguageList.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  updateLangOnChange: PropTypes.bool.isRequired
};

LanguageList.defaultProps = {
  className: "",
  onChange: () => null,
  updateLangOnChange: true
};

export default graphql(UPDATE_USER_PROFILE_MUTATION, {
  name: "updateUserProfileMutation"
})(LanguageList);
