// Vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { graphql } from "react-apollo";
import { withTranslation } from "react-i18next";
import { AvGroup, AvField, AvForm } from "availity-reactstrap-validation";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

// App
import { TRIGGER_PASSWORD_RESET_MUTATION } from "common/Mutations";
import { EMAIL_VALIDATOR } from "common/Validators";
import { CustomAlert, FormLogo } from "components/ui/FunctionalComponents";
import logo from "images/gf365-logo.svg";

class ForgotPasswordForm extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      success: false,
      email: ""
    };

    this.getForgotPasswordForm = this.getForgotPasswordForm.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  getForgotPasswordForm() {
    const { t } = this.props;

    return (
      <AvForm
        onValidSubmit={this.handleValidSubmit}
        onInvalidSubmit={this.handleInvalidSubmit}
      >
        <p className="key-figure text-muted text-center mt-3 mb-2">
          {t("forgot_password")}
        </p>
        <p>{t("forgot_password_instruction_text")}</p>
        {this.state.error && (
          <CustomAlert
            text={t("there_is_no_account_with_this_email")}
          />
        )}

        <AvGroup className="mb-3">
          <div className="icon-input-group icon-input-group-lg">
            <AvField
              className="form-control-lg"
              name="email"
              autoComplete="username"
              placeholder={t("email")}
              validate={EMAIL_VALIDATOR}
              onChange={event => {
                this.setState({ error: false, email: event.target.value });
              }}
            />
            <FontAwesomeIcon
              focusable={false}
              size="sm"
              className="text-black-50"
              icon="envelope"
            />
          </div>
        </AvGroup>

        <Button
          className="mb-4"
          color="secondary"
          block
          size="lg"
          type="submit"
        >
          {t("send")}
        </Button>
      </AvForm>
    );
  }

  handleInvalidSubmit(event, errors, values) {
    this.setState({ errors, values, error: false });
  }

  async handleValidSubmit() {
    const { email } = this.state;

    this.setState({ error: false });

    try {
      await this.props.triggerPasswordResetMutation({ variables: { email } });
      this.setState({ success: true });
      return true;
    } catch (error) {
      this.setState({ error: true });
      return false;
    }
  }

  toggle() {
    this.props.onClose();
    this.setState({ error: false });
  }

  render() {
    const { email, success } = this.state;
    const { t } = this.props;

    const Success = () => (
      <div className="Success text-center">
        <p className="mt-3">
          {t("forgot_password_mail_sent_text")}
          <br />
          <span className="text-secondary">{email}</span>
        </p>
        <Button color="primary" block onClick={this.props.onClose}>
          {t("close")}
        </Button>
      </div>
    );

    return (
      <Modal
        className="ForgotPasswordForm auth-form"
        isOpen={true}
        toggle={this.toggle}
      >
        <ModalHeader toggle={this.toggle} />
        <ModalBody>
          <FormLogo src={logo} />
          {!success && this.getForgotPasswordForm()}
          {success && <Success />}
        </ModalBody>
      </Modal>
    );
  }
}

const ForgotPasswordFormWithMutation = graphql(
  TRIGGER_PASSWORD_RESET_MUTATION,
  {
    name: "triggerPasswordResetMutation"
  }
)(ForgotPasswordForm);

export const ForgotPasswordFormBase = ForgotPasswordForm;
export default withTranslation()(ForgotPasswordFormWithMutation);
