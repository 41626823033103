// Vendor
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback
} from "availity-reactstrap-validation";
import React, { Component } from "react";
import { Mutation } from "react-apollo";
import { withTranslation, Trans } from "react-i18next";
import Recaptcha from "react-recaptcha";
import { Button, Popover, PopoverBody } from "reactstrap";

// App
import { NEWSLETTER_SIGNUP_MUTATION } from "common/Mutations";
import { sitekey } from "config/recaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class NewsletterSignup extends Component {
  constructor() {
    super();

    this.state = { recaptcha: false };

    this.verifyCallback = this.verifyCallback.bind(this);
    this.toggleRecaptcha = this.toggleRecaptcha.bind(this);
  }

  toggleRecaptcha() {
    this.setState({ recaptcha: !this.state.recaptcha });
  }

  /**
   * Callback for recaptcha.
   * Sign up user for newsletter
   * @param {function} newsletterSignup
   * @param {object} input
   */
  verifyCallback(newsletterSignup, input) {
    newsletterSignup({
      variables: { email: input.value }
    });
    input.value = "";
    this.toggleRecaptcha();
  }

  render() {
    const { t } = this.props;
    let input;

    return (
      <Mutation mutation={NEWSLETTER_SIGNUP_MUTATION}>
        {(newsletterSignup, { error, data }) => (
          <div className="NewsletterSignup">
            <AvForm
              onValidSubmit={() => {
                this.setState({ recaptcha: true });
              }}
              ref={el => (this.form = el)}
            >
              <AvGroup className="input-group mb-0">
                <AvInput
                  name="newsletterEmail"
                  id="newsletterEmail"
                  type="email"
                  ref={node => (input = node)}
                  placeholder={t("newsletter")}
                  style={{ borderRadius: "2rem 0 0 2rem" }}
                  className="pl-3"
                  required
                />
                <div className="input-group-append">
                  <Button
                    type="submit"
                    color="primary"
                    id="newsletter-submit"
                    style={{ borderRadius: "0 2rem 2rem 0" }}
                  >
                    <FontAwesomeIcon icon="arrow-right" />
                  </Button>
                  <Popover
                    placement="left"
                    isOpen={this.state.recaptcha}
                    target="newsletter-submit"
                    toggle={this.toggleRecaptcha}
                    className="p-2"
                  >
                    <PopoverBody>
                      <Recaptcha
                        sitekey={sitekey}
                        size="normal"
                        render="explicit"
                        verifyCallback={() =>
                          this.verifyCallback(newsletterSignup, input)
                        }
                      />
                    </PopoverBody>
                  </Popover>
                </div>
                <AvFeedback>{t("enter_valid_email")}</AvFeedback>
              </AvGroup>
            </AvForm>

            {data && data.newsletterSignup && (
              <p className="text-success mt-2 mb-0">
                {t("newsletter_signup_success")}
              </p>
            )}
            {error && (
              <p className="text-danger mt-2 mb-0">
                {t("newsletter_signup_failure")}
              </p>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default withTranslation()(NewsletterSignup);
