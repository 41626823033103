import React, { Component } from "react";
import PropTypes from "prop-types";
import { Alert } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SystemAlert extends Component {
  static propTypes = {
    color: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
      .isRequired,
    dismissable: PropTypes.bool
  };

  static defaultProps = {
    color: "info",
    dismissable: true
  };

  constructor(props) {
    super(props);
    this.state = { visible: true };
    this.onDismiss = this.onDismiss.bind(this);
  }

  onDismiss() {
    this.setState({ visible: false });
  }

  render() {
    const { dismissable, color, message } = this.props;

    let icon;
    switch (color) {
      case "warning":
        icon = <FontAwesomeIcon icon="exclamation-triangle" className="mr-3" />;
        break;
      case "danger":
        icon = <FontAwesomeIcon icon="ban" className="mr-3" />;
        break;
      case "success":
        icon = <FontAwesomeIcon icon="check" className="mr-3" />;
        break;
      case "info":
        icon = <FontAwesomeIcon icon="info" className="mr-3" />;
        break;
      default:
        icon = null;
        break;
    }

    return (
      <Alert
        className="mb-0 position-sticky"
        color={this.props.color}
        isOpen={dismissable ? this.state.visible : true}
        toggle={dismissable ? this.onDismiss : () => null}
      >
        {icon}
        {message}
      </Alert>
    );
  }
}

export default SystemAlert;
