// App
import ApolloClientProvider from "common/ApolloClientProvider";
import { SIGNIN_MUTATION } from "common/Mutations";
import LocaleHelper from "common/LocaleHelper";
import EventEmitterClient from "common/EventEmitterClient";
import { hasLocalStorage } from "common/StorageHelper";
import CustomError from "common/CustomError";

const USER_TOKEN_KEY = "gf365-token";
const USER_KEY = "gf365-user";

class Auth {
  static getToken() {
    if (!hasLocalStorage) return null;
    return localStorage.getItem(USER_TOKEN_KEY);
  }

  static getUser() {
    if (!hasLocalStorage) return null;
    const user = localStorage.getItem(USER_KEY);
    if (!user) return null;
    return JSON.parse(user);
  }

  static isAuthenticated() {
    return hasLocalStorage && localStorage.getItem(USER_TOKEN_KEY) !== null;
  }

  static login(email, password) {
    if (!hasLocalStorage)
      return Promise.reject(
        new CustomError(
          "LOCAL_STORAGE_NOT_SUPPORTED",
          "localStorage is disabled or not supported on this device."
        )
      );

    return new Promise((resolve, reject) => {
      ApolloClientProvider.client
        .mutate({
          mutation: SIGNIN_MUTATION,
          variables: { email, password }
        })
        .then(res => {
          const {
            email,
            firstName,
            language,
            lastName,
            newsletter
          } = res.data.signin.user;

          // Ensure that the UI and store state reflects the current user’s permissions
          // This will cause the store to be cleared and all active queries to be refetched.
          ApolloClientProvider.client.resetStore();

          this.setToken(res.data.signin.token);

          // Populate user obj, but dont inherit props from base class
          let userObj = { firstName, lastName, newsletter, email, language };
          this.setUser(userObj);

          // Helper that takes care of lang update
          LocaleHelper.load(language);

          EventEmitterClient.emit("LOGIN");

          resolve(true);
        })
        .catch(error => {
          //console.log(error);
          reject(error);
        });
    });
  }

  static logout(gotoStart = true) {
    // Ensure that the UI and store state reflects the current user’s permissions
    // This will cause the store to be cleared.
    ApolloClientProvider.client.clearStore();

    if (hasLocalStorage) {
      // Remove token and user from local storage
      localStorage.removeItem(USER_TOKEN_KEY);
      localStorage.removeItem(USER_KEY);
    }

    EventEmitterClient.emit("LOGOUT", gotoStart);
  }

  // Store auth token in local storage so user stays logged in
  static setToken(token) {
    if (hasLocalStorage) localStorage.setItem(USER_TOKEN_KEY, token);
  }

  static setUser(user) {
    if (hasLocalStorage) localStorage.setItem(USER_KEY, JSON.stringify(user));
  }
}

export default Auth;
