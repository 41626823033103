import { library as iconFontLibrary } from "@fortawesome/fontawesome-svg-core";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import {
  faCalendarAlt,
  faCalendarTimes,
  faClock
} from "@fortawesome/free-regular-svg-icons";
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArrowRight,
  faBan,
  faBars,
  faBeer,
  faCar,
  faCheck,
  faConciergeBell,
  faEnvelope,
  faExclamationTriangle,
  faGlobe,
  faGolfBall,
  faHandHoldingUsd,
  faInfo,
  faLocationArrow,
  faImages,
  faInfoCircle,
  faLock,
  faMapMarkerAlt,
  faMoneyBill,
  faMousePointer,
  faQuestionCircle,
  faShare,
  faSpinner,
  faShoppingBasket,
  faStore,
  faShower,
  faSortAmountDown,
  faStar,
  faStopwatch,
  faSun,
  faSuitcaseRolling,
  faTag,
  faTaxi,
  faTicketAlt,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUserGraduate,
  faUser,
  faUserCircle,
  faUtensils
} from "@fortawesome/free-solid-svg-icons";

export default function init() {
  iconFontLibrary.add(
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faArrowRight,
    faBan,
    faBars,
    faBeer,
    faCalendarAlt,
    faCalendarTimes,
    faCar,
    faCheck,
    faClock,
    faConciergeBell,
    faEnvelope,
    faExclamationTriangle,
    faFacebook,
    faGlobe,
    faGolfBall,
    faHandHoldingUsd,
    faImages,
    faInfo,
    faInfoCircle,
    faInstagram,
    faLocationArrow,
    faLock,
    faMapMarkerAlt,
    faMoneyBill,
    faMousePointer,
    faQuestionCircle,
    faShare,
    faShoppingBasket,
    faShower,
    faSortAmountDown,
    faSpinner,
    faStar,
    faStopwatch,
    faStore,
    faSun,
    faSuitcaseRolling,
    faTag,
    faTaxi,
    faTicketAlt,
    faTimes,
    faTimesCircle,
    faTrashAlt,
    faUserGraduate,
    faUser,
    faUserCircle,
    faUtensils
  );
}
