// Vendor
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// App
import Auth from "common/Auth";
import LocaleHelper from "common/LocaleHelper";
import PathDetector from "config/detector";
import FlagIcon from "components/ui/FlagIcon";
import EventEmitterClient from "common/EventEmitterClient";
import LanguageList from "components/ui/LanguageList";

class LanguageDropdown extends Component {
  static propTypes = {
    alignRight: PropTypes.bool,
    direction: PropTypes.string,
    inNavbar: PropTypes.bool,
    onChange: PropTypes.func,
    showLabel: PropTypes.bool,
    toggleClasses: PropTypes.string,
    updateLangOnChange: PropTypes.bool
  };

  static defaultProps = {
    direction: "down",
    alignRight: false,
    inNavbar: false,
    onChange: () => null,
    showLabel: true,
    toggleClasses: ""
  };

  constructor(props) {
    super(props);

    const user = Auth.getUser();
    const langFromPath = PathDetector.lookup();
    let language;

    if (langFromPath) language = langFromPath;
    else if (user) language = user.language;

    const locale = LocaleHelper.getLocale(language);

    this.state = {
      label: locale.label,
      country: locale.country,
      dropdownOpen: false
    };

    this.toggle = this.toggle.bind(this);
    this.updateLocale = this.updateLocale.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    EventEmitterClient.on("LOCALE_CHANGE", this.updateLocale);
  }

  componentWillUnmount() {
    this._isMounted = false;
    EventEmitterClient.removeEventListener("LOCALE_CHANGE", this.updateLocale);
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  updateLocale(locale) {
    locale = locale || LocaleHelper.getLocale();

    if (this._isMounted) {
      this.setState({
        language: locale.language,
        label: locale.label,
        country: locale.country
      });
    }
  }

  render() {
    return (
      <Dropdown
        inNavbar={this.props.inNavbar}
        direction={this.props.direction}
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
      >
        <DropdownToggle
          nav
          style={{ whiteSpace: "nowrap" }}
          className={`${this.props.toggleClasses}`}
          caret
        >
          <FlagIcon code={this.state.country} />
          {this.props.showLabel && (
            <span className="ml-2 d-inline-block">{this.state.label}</span>
          )}
        </DropdownToggle>
        <DropdownMenu right={this.props.alignRight}>
          <LanguageList
            className="dropdown-item rounded-0"
            updateLangOnChange={this.props.updateLangOnChange}
            onChange={locale => {
              this.setState({
                country: locale.country,
                label: locale.label,
                dropdownOpen: false
              });

              this.props.onChange(locale.language);
            }}
          />
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default LanguageDropdown;
