import React from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { COOKIE_POLICY_ROUTE } from "common/RoutesHelper";

export const StorageDisabledMessage = ({ className = "" }) => (
  <p className={className}>
    You are using this site with restricted functionality. Enable{" "}
    <a
      href="https://en.wikipedia.org/wiki/Web_storage"
      target="_blank"
      rel="noopener noreferrer"
    >
      Web Storage
    </a>{" "}
    or update your browser to fully use this site.
  </p>
);

export const CookiePolicyMessage = () => (
  <Trans i18nKey="cookie_consent_text">
    This site is using <Link to={COOKIE_POLICY_ROUTE.url()}>cookies</Link> to
    enhance your experience.
  </Trans>
);
